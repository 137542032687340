import "./css/swiper-bundle.min.css";
import "./css/locomotive-scroll.css";
import "./css/common.css";
import "./css/main.css";
import "./css/sub.css";
import "./css/responsive.css";

import detectBrowser from "./js/plugins/detectBrowser.js";
import gsap from "./js/plugins/gsap.min.js";
import ScrollToPlugin from "./js/plugins/ScrollToPlugin.min.js";
import ScrollTrigger from "./js/plugins/ScrollTrigger.min.js";
import script from "./js/script.js";
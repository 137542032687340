gsap.registerPlugin(ScrollTrigger);
// Event
(function () {
	var unlistenableWindowEvents = {
		click: 1,
		dblclick: 1,
		keyup: 1,
		keypress: 1,
		keydown: 1,
		mousedown: 1,
		mouseup: 1,
		mousemove: 1,
		mouseover: 1,
		mouseenter: 1,
		mouseleave: 1,
		mouseout: 1,
		storage: 1,
		storagecommit: 1,
		textinput: 1
	};

	function indexOf(array, element) {
		var
		index = -1,
		length = array.length;

		while (++index < length) {
			if (index in array && array[index] === element) {
				return index;
			}
		}

		return -1;
	}

	var existingProto = (window.Event && window.Event.prototype) || null;
	window.Event = Window.prototype.Event = function Event(type, eventInitDict) {
		if (!type) {
			throw new Error('Not enough arguments');
		}

		// Shortcut if browser supports createEvent
		if ('createEvent' in document) {
			var event = document.createEvent('Event');
			var bubbles = eventInitDict && eventInitDict.bubbles !== undefined ? eventInitDict.bubbles : false;
			var cancelable = eventInitDict && eventInitDict.cancelable !== undefined ? eventInitDict.cancelable : false;

			event.initEvent(type, bubbles, cancelable);

			return event;
		}

		var event = document.createEventObject();

		event.type = type;
		event.bubbles = eventInitDict && eventInitDict.bubbles !== undefined ? eventInitDict.bubbles : false;
		event.cancelable = eventInitDict && eventInitDict.cancelable !== undefined ? eventInitDict.cancelable : false;

		return event;
	};
	if (existingProto) {
		Object.defineProperty(window.Event, 'prototype', {
			configurable: false,
			enumerable: false,
			writable: true,
			value: existingProto
		});
	}

	if (!('createEvent' in document)) {
		window.addEventListener = Window.prototype.addEventListener = Document.prototype.addEventListener = Element.prototype.addEventListener = function addEventListener() {
			var
			element = this,
			type = arguments[0],
			listener = arguments[1];

			if (element === window && type in unlistenableWindowEvents) {
				throw new Error('In IE8 the event: ' + type + ' is not available on the window object. Please see https://github.com/Financial-Times/polyfill-service/issues/317 for more information.');
			}

			if (!element._events) {
				element._events = {};
			}

			if (!element._events[type]) {
				element._events[type] = function (event) {
					var
					list = element._events[event.type].list,
					events = list.slice(),
					index = -1,
					length = events.length,
					eventElement;

					event.preventDefault = function preventDefault() {
						if (event.cancelable !== false) {
							event.returnValue = false;
						}
					};

					event.stopPropagation = function stopPropagation() {
						event.cancelBubble = true;
					};

					event.stopImmediatePropagation = function stopImmediatePropagation() {
						event.cancelBubble = true;
						event.cancelImmediate = true;
					};

					event.currentTarget = element;
					event.relatedTarget = event.fromElement || null;
					event.target = event.target || event.srcElement || element;
					event.timeStamp = new Date().getTime();

					if (event.clientX) {
						event.pageX = event.clientX + document.documentElement.scrollLeft;
						event.pageY = event.clientY + document.documentElement.scrollTop;
					}

					while (++index < length && !event.cancelImmediate) {
						if (index in events) {
							eventElement = events[index];

							if (indexOf(list, eventElement) !== -1 && typeof eventElement === 'function') {
								eventElement.call(element, event);
							}
						}
					}
				};

				element._events[type].list = [];

				if (element.attachEvent) {
					element.attachEvent('on' + type, element._events[type]);
				}
			}

			element._events[type].list.push(listener);
		};

		window.removeEventListener = Window.prototype.removeEventListener = Document.prototype.removeEventListener = Element.prototype.removeEventListener = function removeEventListener() {
			var
			element = this,
			type = arguments[0],
			listener = arguments[1],
			index;

			if (element._events && element._events[type] && element._events[type].list) {
				index = indexOf(element._events[type].list, listener);

				if (index !== -1) {
					element._events[type].list.splice(index, 1);

					if (!element._events[type].list.length) {
						if (element.detachEvent) {
							element.detachEvent('on' + type, element._events[type]);
						}
						delete element._events[type];
					}
				}
			}
		};

		window.dispatchEvent = Window.prototype.dispatchEvent = Document.prototype.dispatchEvent = Element.prototype.dispatchEvent = function dispatchEvent(event) {
			if (!arguments.length) {
				throw new Error('Not enough arguments');
			}

			if (!event || typeof event.type !== 'string') {
				throw new Error('DOM Events Exception 0');
			}

			var element = this, type = event.type;

			try {
				if (!event.bubbles) {
					event.cancelBubble = true;

					var cancelBubbleEvent = function (event) {
						event.cancelBubble = true;

						(element || window).detachEvent('on' + type, cancelBubbleEvent);
					};

					this.attachEvent('on' + type, cancelBubbleEvent);
				}

				this.fireEvent('on' + type, event);
			} catch (error) {
				event.target = element;

				do {
					event.currentTarget = element;

					if ('_events' in element && typeof element._events[type] === 'function') {
						element._events[type].call(element, event);
					}

					if (typeof element['on' + type] === 'function') {
						element['on' + type].call(element, event);
					}

					element = element.nodeType === 9 ? element.parentWindow : element.parentNode;
				} while (element && !event.cancelBubble);
			}

			return true;
		};

		// Add the DOMContentLoaded Event
		document.attachEvent('onreadystatechange', function() {
			if (document.readyState === 'complete') {
				document.dispatchEvent(new Event('DOMContentLoaded', {
					bubbles: true
				}));
			}
		});
	}
}());
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}
(function () {

  if ( typeof window.CustomEvent === "function" ) return false;

  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
   }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();

(function() {
  var throttle = function(type, name, obj) {
      obj = obj || window;
      var running = false;
      var func = function() {
          if (running) { return; }
          running = true;
           requestAnimationFrame(function() {
              obj.dispatchEvent(new CustomEvent(name));
              running = false;
          });
      };
      obj.addEventListener(type, func);
  };

  /* init - you can init any event */
  throttle("resize", "optimizedResize");
})();
var clickEvent = (function () {
	if ('ontouchstart' in document.documentElement === true) {
		return 'touchstart';
	} else {
		return 'click';
	}
})();
function siblings(t) {
  var children = t.parentElement.children;
  var tempArr = [];

  for (var i = 0; i < children.length; i++) {
    tempArr.push(children[i]);
  }

  return tempArr.filter(function(e){
    return e != t;
  });
}

var header = document.getElementById('header');
var hamberger = document.getElementsByClassName('hamberger')[0];
var gnb = document.getElementById('gnb');
var pcQuick = document.getElementById('pcQuick');
var pcQuickBtn = pcQuick.querySelector('.quick-btn');
var mQuick = document.getElementById('mQuick');
var mQuickBtn = mQuick.querySelector('.quick-btn');
var mainVideo = document.getElementById('mainVideo');

const menuEvent = function(){
	//document.body.classList.toggle('open');
	header.classList.toggle('open');
	this.classList.toggle('open');
	gnb.classList.toggle('open');
	//gnb.nextElementSibling.classList.toggle('open');
}
function removeClass(elem,className){
  let max = elem.length;
  for(let i=0;i<max; i++){
    elem[i].classList.remove(className);
  }
}
//클릭 이벤트
hamberger.addEventListener(clickEvent,menuEvent);

const quickEvent = function(){
  this.parentNode.classList.toggle('open');
}

//퀵메뉴 이벤트
pcQuickBtn.addEventListener(clickEvent,quickEvent);
mQuickBtn.addEventListener(clickEvent,quickEvent);




const menuItem = document.querySelectorAll('#topNavigation .tnb-depth1');
const menuDepth2 = document.querySelectorAll('#topNavigation .tnb-depth2');

function hide(elem){
  gsap.set(elem, {autoAlpha: 0});
}

menuDepth2.forEach(el=>hide(el));
menuItem.forEach(el=>{
	el.addEventListener("mouseenter",function(event){
		const depth2 = event.target.querySelector('#topNavigation .tnb-depth2');
    if(depth2){
      gsap.to(depth2,{autoAlpha:1})
    }
		
	});
	el.addEventListener("mouseleave",function(event){
		const depth2 = event.target.querySelector('#topNavigation .tnb-depth2');
    if(depth2){
      gsap.to(depth2,{autoAlpha:0})
    }
		
	});
})




const equipSect = document.getElementsByClassName('equipment-sect')[0];
const equipList = document.querySelectorAll('.equipment__list li');
const equipMenu = Array.from(document.getElementsByClassName('equipment-menu-item'));
const equipVidIframe = document.querySelector('.equipment__video iframe');

function videoChange(el){
  var hoverVideoId = el.getAttribute('data-video-id');;
  var hoverVideoSrc = `https://player.vimeo.com/video/${hoverVideoId}?loop=1&amp;background=1`;
  equipVidIframe.setAttribute('src',hoverVideoSrc);
}


if(equipSect){
  equipSect.addEventListener('mouseleave',function(){
    equipMenu.forEach(equips=>gsap.to(equips,{autoAlpha:0}));
    gsap.to('.equipment__video',{autoAlpha:0});
  });
  equipList.forEach((equip,index)=>{
    equip.addEventListener('mouseenter',function(){
      equipMenu.forEach(el=>gsap.to(el,{autoAlpha:0}));
      gsap.to(equipMenu[index],{autoAlpha:1});
      equipList.forEach(equips=>equips.classList.remove('hover'));
      equipList[index].classList.add('hover');
      gsap.to('.equipment__video',{autoAlpha:1});
      videoChange(equip);
    });
    equip.addEventListener('mouseleave',function(){
      equipList[index].classList.remove('hover');
    });
  });
  equipMenu.forEach(equips=>gsap.set(equips,{autoAlpha:0}));
  
}

const bnaLinks = document.querySelectorAll('.main-bna-tablist li');
const bnaContent = Array.from(document.getElementsByClassName('main-bna-content'));

if(bnaContent){
  gsap.set(bnaContent[0],{autoAlpha:1});
  bnaLinks.forEach((link,index)=>{
    link.addEventListener(clickEvent,(e)=>{
      openTab(e, index);
    })
  });
  
  bnaContent.forEach((bna)=>{
    const bnaSlide = bna.getElementsByClassName('swiper-container')[0];
    const bnaPrev = bna.querySelector('.ctrls.prev');
    const bnaNext = bna.querySelector('.ctrls.next');
  
    const bnaswiper = new Swiper(bnaSlide, {
      // Default parameters
      spaceBetween:10,
      navigation: {
        nextEl: bnaNext,
        prevEl: bnaPrev,
      },
    });
  });

  function openTab(evt, index) {
  
    // Get all elements with class="tabcontent" and hide them
    for (i = 0; i < bnaContent.length; i++) {
      gsap.set(bnaContent[i],{autoAlpha:0});
      //bnaContent[i].style.display = "none";
    }
  
    // Get all elements with class="tablinks" and remove the class "active"
    for (i = 0; i < bnaLinks.length; i++) {
      bnaLinks[i].className = bnaLinks[i].className.replace(" active", "");
    }
  
    // Show the current tab, and add an "active" class to the button that opened the tab
    gsap.to(bnaContent[index],{autoAlpha:1});
    evt.currentTarget.classList.add("active");
  }
}







const MsubjectSlide = new Swiper('.intro-m-subject-slide', {
  // scrollbar: {
  //   el: '.swiper-scrollbar',
  //   draggable: true,
  // },
  effect:'fade',
  autoplay: {
    delay: 4000,
  },
  loop:true
});

// const McareSlide = new Swiper('.doctoromy-m-care-slide', {
//   scrollbar: {
//     el: '.swiper-scrollbar',
//     draggable: true,
//   },
//   autoplay: {
//     delay: 4000,
//   },
//   spaceBetween: 20,
//   // Responsive breakpoints
//   breakpoints: {
//     // when window width is >= 320px
//     320: {
//       slidesPerView: 1.2
//     },
//     // when window width is >= 480px
//     480: {
//       slidesPerView: 1.3
//     },
//     // when window width is >= 640px
//     640: {
//       slidesPerView: 1.4
//     }
//   }
// });


const subEquipSlide = new Swiper('.sub1-1-equip-slide', {
  autoplay: {
    delay: 4000,
  },
  navigation: {
    nextEl: '.ctrls2.next',
    prevEl: '.ctrls2.prev',
  },
  spaceBetween: 20,
  // Responsive breakpoints
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1.2,
      centeredSlides:true
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 2,
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 2
    }
  }
});

const interiorSlides = document.querySelectorAll('.interior-slide-area div[class*=interior-slide0]');
if(interiorSlides){
  interiorSlides.forEach((slide)=>{
    const nextEl = slide.parentElement.querySelector('.ctrls2.next');
    const prevEl = slide.parentElement.querySelector('.ctrls2.prev');
    const interiorSlide = new Swiper(slide, {
      navigation: {
        nextEl,
        prevEl,
      },
      effect:'fade',
      autoplay: {
        delay: 4000,
      },
      loop:true
    });
  })
}


const bottomSlide = new Swiper('.mobile-bottom-slide', {
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
  },
  autoplay: {
    delay: 4000,
  },
  loop:true
});

const subPoint = document.getElementsByClassName('sub-bottom-point');
const subPointList = document.querySelectorAll('ul.point-list > li');
const subPointItem = Array.from(document.getElementsByClassName('point-bg'));
var poinTtimer,currentNum,PointsLength;

function initTimer(){
  poinTtimer = setInterval(timerHandler,4000);
}

function timerHandler(){
  currentNum++;
  if(currentNum == PointsLength){
    currentNum = 0;
  }
  showPoint(currentNum);
  changeClass(currentNum);
}

function changeClass(index){
  var siblingItem = Array.prototype.filter.call(subPointList[index].parentNode.children, function(child){
    return child !== subPointList[index];
  });
  siblingItem.forEach((item,idx)=>{
    item.classList.remove('active');
  });
  subPointList[index].classList.add('active');
}

function showPoint(index){
  var siblingItem = Array.prototype.filter.call(subPointItem[index].parentNode.children, function(child){
    return child !== subPointItem[index];
  });
  siblingItem.forEach((item,idx)=>{
    gsap.to(item,{
      autoAlpha:0,
    })
  })
  gsap.to(subPointItem[index],{
    autoAlpha:1,
  });
}



if(subPoint){
  var currentNum = 0;
  var PointsLength = subPointList.length;
  if(PointsLength){
    initTimer();
  }
  
  subPointList.forEach((list,idx)=>{
    list.addEventListener('mouseenter',function(){
      clearInterval(poinTtimer);
      currentNum = idx;
      showPoint(idx);
      changeClass(idx);
    });
    list.addEventListener('mouseleave',function(){
      initTimer();
    });
  })
}


const boTable = document.querySelector('.bbs-table-visual');
let locoScroll;

if(boTable){
  locoScroll = new LocomotiveScroll({
    el: document.querySelector(".smooth-scroll"),
    smooth: false
  })

} else{
  locoScroll = new LocomotiveScroll({
    el: document.querySelector(".smooth-scroll"),
    smooth: true
  });
  
}

// each time Locomotive Scroll updates, tell ScrollTrigger to update too (sync positioning)
locoScroll.on("scroll", ScrollTrigger.update);

ScrollTrigger.scrollerProxy(".smooth-scroll", {
  scrollTop(value) {
    return arguments.length ? locoScroll.scrollTo(value, 0, 0) : locoScroll.scroll.instance.scroll.y;
  }, // we don't have to define a scrollLeft because we're only scrolling vertically.
  getBoundingClientRect() {
    return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
  },
  // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
  pinType: document.querySelector(".smooth-scroll").style.transform ? "transform" : "fixed"
});

const introSubject = document.getElementsByClassName('intro-subject-sect')[0];
const pinTitItem = Array.from(document.getElementsByClassName('pin-title-item'));
const pinIllustItem = Array.from(document.getElementsByClassName('pin-illust-item'));

var options01 = {
  id: 547759329,
  width: 1920,
  background:true
};
var options02 = {
  id: 560713453,
  width: 720,
  background:true
};


ScrollTrigger.matchMedia({
	
  // desktop
  "(min-width: 800px)": function() {
    // setup animations and ScrollTriggers for screens 800px wide or greater (desktop) here...
    // These ScrollTriggers will be reverted/killed when the media query doesn't match anymore.
    if(introSubject){
      pinTitItem.forEach(element => gsap.set(element,{autoAlpha:0}));
      pinIllustItem.forEach(element => gsap.set(element,{autoAlpha:0}));
      var tl = gsap.timeline({
        scrollTrigger:{
          trigger:introSubject,
          scroller: ".smooth-scroll",
          scrub: true,
          pin: true,
          start: "top top",
          end: "+=600%",
          onLeave:()=>{
            header.classList.remove('index-header');
            header.classList.add('fixed-header');
          },
          onEnterBack:()=>{
            header.classList.add('index-header');
            header.classList.remove('fixed-header');
          }
        }
      });
      tl.to('.pin-scroll-area',{scale:1.9,xPercent:-50, yPercent:-36, ease: "power1"},"<")
        .to('.pin-title-item.inofit',{autoAlpha:1},0)
        .to('.pin-illust-item.inofit',{autoAlpha:1},0)
        .to('.pin-title-item.inofit',{autoAlpha:0},0.25)
        .to('.pin-illust-item.inofit',{autoAlpha:0},0.25)
        .fromTo('.pin-scroll-area',{xPercent:-50,yPercent:-36},{xPercent:-50,yPercent:-25},0.4)
        .to('.pin-title-item.stitch',{autoAlpha:1},0.6)
        .to('.pin-illust-item.stitch',{autoAlpha:1},0.6)
        .to('.pin-scroll-area',{scale:1,xPercent:-50,yPercent:-50},0.8)
        .to('.pin-title-item.stitch',{autoAlpha:1},1)
        .to('.pin-illust-item.stitch',{autoAlpha:1},1)
        //.to('.pin-title-item.lifting',{autoAlpha:1},1)
        //.to('.pin-illust-item.lifting',{autoAlpha:1},1)
    }

    // Set up our scroll trigger
    const pinTl = gsap.timeline();
    pinTl.to('.prog-bar',{
      scrollTrigger:{
        trigger: ".pin-simple-container",
        scroller: ".smooth-scroll",
        start: "top top",
        end: "bottom bottom",
        //onUpdate: getCurrentSection,
        pin: ".pin-simple",
        scrub: true,
      }, 
      rotation: 360
    });


    if(mainVideo){
      var video01Player = new Vimeo.Player('mainVideo', options01);
    }

    const revealAni = document.getElementsByClassName("gs_reveal");
    if(revealAni){
      gsap.utils.toArray(revealAni).forEach(function(elem, index){
        gsap.set(elem, {autoAlpha: 0}); // assure that the element is hidden when scrolled into view
        const getDelay = elem.getAttribute('data-gs-delay');
      
        var x = 0,
          y = 60,
          z = -100;
      
        if (elem.classList.contains('reveal_fromDown')) {
          y = -12
        } else if(elem.classList.contains("reveal_fromUp")){
          y = 12;
        } else if (elem.classList.contains("reveal_fromRight")) {
          x = 100;
          y = 0;
          z = 10;
        } else if (elem.classList.contains("reveal_fromLeft")) {
          x = -100;
          y = 0;
          z = 10;
        } 
        gsap.fromTo(elem, {
          x: x,
          y: y,
          z: z,
          autoAlpha: 0
        }, {
          scrollTrigger: {
            trigger: elem.parentNode,
            scroller: ".smooth-scroll",
            start: 'top 66%',
            toggleActions: 'play none none none',
            onEnter:()=>elem.classList.add('active'),
            //toggleClass: {className: 'waypoint', targets: '.main-header, #gnb'}
            //markers:true
          },
          duration: .8,
          x: 0,
          y: 0,
          z: 0,
          autoAlpha: 1,
          ease: Power2.easeOut,
          overwrite: "auto",
          delay: getDelay * 0.001
        });
      });
    }/* gs_reveal이 있을때만 실행 */

  },

  // mobile
  "(max-width: 799px)": function() {
    // The ScrollTriggers created inside these functions are segregated and get
    // reverted/killed when the media query doesn't match anymore. 
    const mainVisualSect = document.getElementsByClassName('main-visual-sect')[0];
    if(mainVisualSect){
      header.classList.remove('index-header');
      ScrollTrigger.create({
        trigger:mainVisualSect,
        scroller: ".smooth-scroll",
        start: "top top",
        end: "bottom top",
        onEnter:()=>header.classList.add('fixed-header'),
        //onLeave:()=>header.classList.add('fixed-header'),
        //onEnterBack:()=>header.classList.remove('fixed-header')
      });
    }

    let gnbMenu = document.getElementById('menu-main-menu');
    gnbMenu.addEventListener(clickEvent,function(e){
      this.tg = e.target;
      this.menuList = this.tg.parentNode;

      if(this.tg.nextElementSibling.classList.contains('gnb-depth2')) {
        e.preventDefault();
        if(this.menuList.classList.contains('active')){
          //클릭한 메뉴에 active가 있을때
          removeClass(gnbMenu,'on');
          this.menuList.classList.remove('active');
          gsap.fromTo(this.menuList,{
              height: this.tg.offsetHeight + this.tg.nextElementSibling.offsetHeight,
            },
            {
              height: this.tg.offsetHeight,
              duration: .6
            });
        } else if(!this.menuList.classList.contains('on')){
          //내가 클릭한 메뉴에 on이 없을때 active가 없을때
          removeClass(gnbMenu,'on');
          this.menuList.classList.add('on');
          gsap.to(this.menuList,
            {
              height: this.tg.offsetHeight + this.tg.nextElementSibling.offsetHeight,
              duration: .6
            });
        } else{
          //클릭한 메뉴에 이미 on이 있을때
          this.menuList.classList.remove('on');
          gsap.to(this.menuList,
            {
              height: this.tg.offsetHeight,
              duration: .6
            });
        }
        
      }

    });

    if(mainVideo){
      var video01Player = new Vimeo.Player('mainVideo', options02);
    }


    const revealAni = document.getElementsByClassName("gs_reveal");
    if(revealAni){
      gsap.utils.toArray(revealAni).forEach(function(elem, index){
        gsap.set(elem, {autoAlpha: 0}); // assure that the element is hidden when scrolled into view
        const getDelay = elem.getAttribute('data-gs-delay');
      
        var x = 0,
          y = 60,
          z = -100;
      
        if (elem.classList.contains('reveal_fromDown')) {
          y = -12
        } else if(elem.classList.contains("reveal_fromUp")){
          y = 12;
        } else if (elem.classList.contains("reveal_fromRight")) {
          x = 30;
          y = 0;
          z = 10;
        } else if (elem.classList.contains("reveal_fromLeft")) {
          x = -30;
          y = 0;
          z = 10;
        } 
        gsap.fromTo(elem, {
          x: x,
          y: y,
          z: z,
          autoAlpha: 0
        }, {
          scrollTrigger: {
            trigger: elem.parentNode,
            scroller: ".smooth-scroll",
            start: 'top 75%',
            toggleActions: 'play none none none',
            onEnter:()=>elem.classList.add('active'),
            //toggleClass: {className: 'waypoint', targets: '.main-header, #gnb'}
            //markers:true
          },
          duration: .8,
          x: 0,
          y: 0,
          z: 0,
          autoAlpha: 1,
          ease: Power2.easeOut,
          overwrite: "auto",
          delay: getDelay * 0.001
        });
      });
    }/* gs_reveal이 있을때만 실행 */

    gsap.to(".main-after-service .mobile-scroll-box", {
			xPercent: -100, 
			x: (innerWidth/1.2),
			ease: "none",
			scrollTrigger: {
        trigger: ".main-after-service",
        start: "top 60",
        end: '+=150%',
        scrub: true,
        pin: true,
        anticipatePin: 1
			}
    });

    gsap.to(".doctoromy-m-care-slide", {
			xPercent: -100, 
			x: (innerWidth/1.2),
			ease: "none",
			scrollTrigger: {
        trigger: ".doctoromy-m-care",
        start: "top 100",
        end: '+=150%',
        scrub: true,
        pin: true,
        anticipatePin: 1
			}
    });
    
    
    /* 220817 - 추가 */
    const m_animation0 = ScrollTrigger.create({
      trigger: "#m_animation0",
      scroller: ".smooth-scroll",
      start: "top 75%",
      end: 99999,
      toggleClass: {className: 'm_animation_on', targets: '#m_animation0'}
    });
    
    const m_animation00 = ScrollTrigger.create({
      trigger: "#m_animation00",
      scroller: ".smooth-scroll",
      start: "top 75%",
      end: 99999,
      toggleClass: {className: 'm_animation_on', targets: '#m_animation00'}
    });
    
    const m_animation01 = ScrollTrigger.create({
      trigger: "#m_animation01",
      scroller: ".smooth-scroll",
      start: "top 75%",
      end: 99999,
      toggleClass: {className: 'm_animation_on', targets: '#m_animation01'}
    });
    
    const m_animation02 = ScrollTrigger.create({
      trigger: "#m_animation02",
      scroller: ".smooth-scroll",
      start: "top 75%",
      end: 99999,
      toggleClass: {className: 'm_animation_on', targets: '#m_animation02'}
    });
    
    const m_animation03 = ScrollTrigger.create({
      trigger: "#m_animation03",
      scroller: ".smooth-scroll",
      start: "top 75%",
      end: 99999,
      toggleClass: {className: 'm_animation_on', targets: '#m_animation03'}
    });
    
    const m_animation04 = ScrollTrigger.create({
      trigger: "#m_animation04",
      scroller: ".smooth-scroll",
      start: "top 75%",
      end: 99999,
      toggleClass: {className: 'm_animation_on', targets: '#m_animation04'}
    });
    
    const m_animation05 = ScrollTrigger.create({
      trigger: "#m_animation05",
      scroller: ".smooth-scroll",
      start: "top 75%",
      end: 99999,
      toggleClass: {className: 'm_animation_on', targets: '#m_animation05'}
    });
    
    const m_animation06 = ScrollTrigger.create({
      trigger: "#m_animation06",
      scroller: ".smooth-scroll",
      start: "top 75%",
      end: 99999,
      toggleClass: {className: 'm_animation_on', targets: '#m_animation06'}
    });
    
    const m_animation07 = ScrollTrigger.create({
      trigger: "#m_animation07",
      scroller: ".smooth-scroll",
      start: "top 75%",
      end: 99999,
      toggleClass: {className: 'm_animation_on', targets: '#m_animation07'}
    });
    
    const m_animation08 = ScrollTrigger.create({
      trigger: "#m_animation08",
      scroller: ".smooth-scroll",
      start: "top 75%",
      end: 99999,
      toggleClass: {className: 'm_animation_on', targets: '#m_animation08'}
    });
    
    const m_animation09 = ScrollTrigger.create({
      trigger: "#m_animation09",
      scroller: ".smooth-scroll",
      start: "top 75%",
      end: 99999,
      toggleClass: {className: 'm_animation_on', targets: '#m_animation09'}
    });
    
    const m_animation10 = ScrollTrigger.create({
      trigger: "#m_animation10",
      scroller: ".smooth-scroll",
      start: "top 75%",
      end: 99999,
      toggleClass: {className: 'm_animation_on', targets: '#m_animation10'}
    });
    
    const m_animation11 = ScrollTrigger.create({
      trigger: "#m_animation11",
      scroller: ".smooth-scroll",
      start: "top 75%",
      end: 99999,
      toggleClass: {className: 'm_animation_on', targets: '#m_animation11'}
    });
    /* //220817 - 추가 */
    
    
    
    
  },
	
  // all 
  "all": function() {
    // ScrollTriggers created here aren't associated with a particular media query,
    // so they persist.
    gsap.utils.toArray(".parallax").forEach((section, i) => {
      section.bg = section.querySelector(".bg");
      section.txt = section.querySelector(".pContent");
      let elemHeight = section.bg.clientHeight;
      section.bg.style.backgroundPosition = "50% 0px"; 
      gsap.to(section.bg, {
        backgroundPosition: `50% ${elemHeight / 2}px`,
        ease: "none",
        scrollTrigger: {
          trigger: section,
          scroller: ".smooth-scroll",
          start: "top top", 
          end: "bottom top",
          scrub: true
        }
      });
      gsap.to(section.txt, {
        yPercent: 50,
        autoAlpha:0,
        ease: "none",
        scrollTrigger: {
          trigger: section,
          scroller: ".smooth-scroll",
          start: "top top",
          end: "bottom top",
          scrub: true
        },
      });
    });

    const headerHeight = parseFloat(getComputedStyle(header, null).height.replace("px", ""));
    const subNavPin = ScrollTrigger.create({
      trigger: ".sub-nav-wrap",
      scroller: ".smooth-scroll",
      start: "top " + headerHeight,
      end: 999999,
      pin: ".sub-navigation",
      toggleClass: {className: 'fixed-nav', targets: '.sub-nav-wrap'}
    });

    const subNavPin2 = ScrollTrigger.create({
      trigger: ".nav-wrap",
      scroller: ".smooth-scroll",
      start: "top " + headerHeight,
      end: 999999,
      pin: ".nav-skin-care",
      toggleClass: {className: 'fixed-nav', targets: '.nav-skin-care'}
    });

    const changeHeader = document.getElementsByClassName('change-header')[0];
    if(changeHeader){
      header.classList.add('index-header');
      ScrollTrigger.create({
        trigger: changeHeader,
        scroller: ".smooth-scroll",
        start: "top top",
        end: 'bottom top',
        onEnter:()=>{header.classList.remove('index-header'); },
        onEnterBack:()=>{header.classList.add('index-header'); },
        onLeave:()=>{header.classList.remove('index-header'); },
        onLeaveBack:()=>{header.classList.add('index-header'); },
        //markers:true
        //toggleClass: {className: 'index-header', targets: '.main-header'}
      });
    }

    const subtop = document.getElementsByClassName('sub-top-visual')[0];
    if(subtop){
      ScrollTrigger.create({
        trigger: ".sub-top-visual",
        scroller: ".smooth-scroll",
        start: 'top top',
        end: 'bottom top',
        onEnter:()=>{
          header.classList.remove('index-header');
          header.classList.add('fixed-header');
        },
        onLeave:()=>{
          //header.classList.add('index-header');
          header.classList.add('fixed-header');
        },
        onEnterBack:()=>{
          //header.classList.add('index-header');
          header.classList.remove('fixed-header');
        },
        onLeaveBack:()=>{header.classList.remove('fixed-header');},
        //markers:true
        //toggleClass: {className: 'fixed-header', targets: header}
      });
    }

    const subLinkNav = document.querySelectorAll(".nav-skin-care-list a");
    if(subLinkNav){
      const subLinkPanel = document.querySelectorAll("[id^=skincare0]");
  
      subLinkNav.forEach(function(a,idx) {
        a.addEventListener("click", function(e) {
          e.preventDefault();
          locoScroll.scrollTo(e.target.getAttribute("href"));
        });
        
      });

      subLinkPanel.forEach(function(anchorPanel,idx){
        /* Panels */
        gsap.to(anchorPanel, {
          scrollTrigger: {
            trigger: anchorPanel,
            scroller: ".smooth-scroll",
            start: "top 100px",
            end:"bottom 100px",
            onEnter:()=>{
              subLinkNav.forEach((a)=>a.parentNode.classList.remove('active'));
              subLinkNav[idx].parentNode.classList.add('active');
              gsap.to('.tablet-scroller', {duration: .2, scrollTo: {x: (subLinkNav[idx].offsetLeft)/1.25}});
            },
            onEnterBack:()=>{
              subLinkNav.forEach((a)=>a.parentNode.classList.remove('active'));
              subLinkNav[idx].parentNode.classList.add('active');
              gsap.to('.tablet-scroller', {duration: .2, scrollTo: {x: (subLinkNav[idx].offsetLeft)/1.25}});
            },
            //toggleClass: {targets: anchorPanel, className: "active"}
          }
        });

      });

    }

    const txtScroll = document.querySelectorAll(".bna-txt-scroll");
    if(txtScroll){
      txtScroll.forEach((elem)=>{
        elem.addEventListener("mouseenter",()=>locoScroll.stop());
        elem.addEventListener("mouseleave",()=>locoScroll.start());
      });
    }

    
  }
	
});

// each time the window updates, we should refresh ScrollTrigger and then update LocomotiveScroll. 
ScrollTrigger.addEventListener("refresh", () => locoScroll.update());

// after everything is set up, refresh() ScrollTrigger and update LocomotiveScroll because padding may have been added for pinning, etc.
ScrollTrigger.refresh();